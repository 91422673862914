import React, { useState } from "react";
import { create as ipfsHttpClient } from "ipfs-http-client";
import { Buffer } from "buffer";
import axios from "axios";
import { toast } from "react-toastify";
import Button from "react-bootstrap/Button";
import { Header } from "../components/Header";
import { useNavigate } from "react-router-dom";

const projectId = "2DWJHtZRz0Y2jpsPSKCxopx5f69";
const projectSecret = "fbe3801f275d21e445197918b7334672";
const projectIdAndSecret = `${projectId}:${projectSecret}`;
const ipfsURL = "https://braands.infura-ipfs.io/ipfs/";

const client = ipfsHttpClient({
  host: "ipfs.infura.io",
  port: 5001,
  protocol: "https",
  headers: {
    authorization: `Basic ${Buffer.from(projectIdAndSecret).toString(
      "base64"
    )}`,
  },
});

const Property = () => {
  const [input, setInput] = useState({
    title: "",
    price: "",
    description: "",
    images: [],
    documents: [],
  });
  let navigate = useNavigate();

  const changeHandler = (e) => {
    setInput({ ...input, [e.target.name]: e.target.value });
  };
  const onFileChange = (e) => {
    setInput({ ...input, [e.target.name]: e.target.files });
  };

  async function uploadFileToIpfs({ images, documents }) {
    const urls = {
      images: [],
      documents: [],
    };
    for (let image of images) {
      const added = await client.add(image, {
        progress: (prog) => {
          console.log(`received: ${prog}`);
        },
      });
      urls.images.push(`${ipfsURL}${added.path}`);
    }
    for (let doc of documents) {
      const added = await client.add(doc, {
        progress: (prog) => {
          console.log(`received: ${prog}`);
        },
      });
      urls.documents.push(`${ipfsURL}${added.path}`);
    }
    return urls;
  }

  async function submitPropertyData() {
    const { title, price, description, images, documents } = input;
    const urls = await uploadFileToIpfs({ images, documents });
    axios
      .post(process.env.REACT_APP_API_ROOT_URL + "property", {
        title,
        price,
        description,
        ...urls,
      })
      .then((response) => {
        toast.success("Successful", {
          position: toast.POSITION.TOP_RIGHT,
        });
        navigate("/marketplace")
      })
      .catch((err) => {
        console.log(err);
        toast.error("Please try after sometime.", {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  }

  return (
    <>
      <Header />
      <section style={{ paddingTop: "150px" }}>
      <div className="container">
        <h1 className="mb-4">Add Property</h1>
        <div className="mb-3">
          <input
            type="text"
            className="form-control"
            name="title"
            placeholder="Enter your title"
            value={input.title}
            onChange={changeHandler}
          />
        </div>
        <div className="mb-3">
          <input
            type="text"
            className="form-control"
            name="price"
            placeholder="Enter price per share"
            value={input.price}
            onChange={changeHandler}
          />
        </div>
        <div className="mb-3">
          <textarea
            className="form-control"
            rows="4"
            name="description"
            value={input.description}
            onChange={changeHandler}
            placeholder="Enter your description"
          ></textarea>
        </div>
        <div className="mb-3">
          <h6 className="mb-3">Images</h6>
          <input type="file" name={"images"} onChange={onFileChange} multiple />
        </div>
        <div className="mb-3">
          <h6 className="mb-3">Documents</h6>
          <input
            type="file"
            name="documents"
            onChange={onFileChange}
            multiple
          />
        </div>
        <div className="mb-3">
          <Button variant="primary" type="button" onClick={submitPropertyData}>
            Add Property
          </Button>
        </div>
      </div>
    </section>
    </>
  );
};

export default Property;
