import React, { useEffect, useState } from "react";
import { Header } from "../components/Header";
import axios from "axios";
import { useNavigate, Link } from "react-router-dom";
import { toast } from "react-toastify";

const Marketplace = () => {
  const [properties, setProperties] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    axios
      .get(process.env.REACT_APP_API_ROOT_URL + "property")
      .then((response) => {
        setProperties(response?.data);
      })
      .catch((err) => {
        console.log(err);
        toast.error("Please try after sometime.", {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  }, []);

  return (
    <>
      <Header />
      <section style={{ paddingTop: "150px" }}>
        <div className="container">
          <h3 className="mb-4">Marketplace</h3>
          <div className="row">
            {properties &&
              properties.map((ele, index) => (
                <div className="col-lg-6 col-md-6 col-xl-4 h-100" key={index}>
                  <div className="property__grid__single column__space--secondary">
                    <div className="img__effect">
                      <Link to={"/property-details?property=" + ele.id}>
                        <img
                          src={ele.images.length > 0 && ele.images[0]}
                          alt="Property"
                          style={{ height: "300px", objectFit: "cover" }}
                        />
                      </Link>
                    </div>
                    <div className="property__grid__single__inner">
                      <h4>{ele.title}</h4>
                      <p className="sub__info">
                        <i className="fa-solid fa-location-dot"></i>{" "}
                        {ele.description}
                      </p>
                      <div className="progress__type">
                        <div className="progress">
                          <div
                            className="progress-bar"
                            role="progressbar"
                            aria-valuenow="25"
                            aria-valuemin="0"
                            aria-valuemax="100"
                          ></div>
                        </div>
                        <p className="project__has">
                          <span className="project__has__investors">
                            {159} Investors
                          </span>
                          |
                          <span className="project__has__investors__amount">
                            <i className="fa-solid fa-dollar-sign"></i> 1,94,196
                          </span>
                          <span className="project__has__investors__percent">
                            (64.73%)
                          </span>
                        </p>
                      </div>
                      <div className="item__info">
                        <div className="item__info__single">
                          <p>Annual Return</p>
                          <h6>7.5% + 2%</h6>
                        </div>
                        <div className="item__info__single">
                          <p>Property Type</p>
                          <h6>Commercial</h6>
                        </div>
                      </div>
                      <div className="invest__cta__wrapper">
                        <div className="invest__cta">
                          <button
                          type="button"
                            onClick={() =>
                              navigate("/property-details?property=" + ele.id)
                            }
                            className="button button--effect"
                          >
                            Buy Now
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </section>
    </>
  );
};

export default Marketplace;
